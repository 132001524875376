import React from "react";
import { Button, tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { CapSteps } from "../../constants/capConstants";
import { SplatProspect } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useCapContext } from "../../providers/CapContext";
import { StepName } from "../../capTypes";

type CapSidebarProps = {
  prospect?: SplatProspect;
  currentStep: StepName;
  changeStep: (step: StepName) => void;
};

const CapNav = ({ prospect, currentStep, changeStep }: CapSidebarProps) => {
  const goToStep = (step: StepName) => {
    changeStep(step);
  };

  const { cap } = useCapContext();

  const filteredCapSteps = CapSteps.filter((step) => {
    return !(
      (cap?.type === "A" || cap?.type === "B") &&
      step.name === "Home readiness"
    );
  });

  return (
    <SidebarNav>
      <StepsList>
        {filteredCapSteps.map((capStep) => {
          return (
            <CapNavItem
              key={capStep.name}
              onClick={() => goToStep(capStep.name)}
              style={{
                borderRadius: "unset",
                borderBottom:
                  capStep.name === currentStep
                    ? `3px solid ${tokens.BRAND_HERO_BLUE}`
                    : "none",
              }}
            >
              <Typography
                size={17}
                color={
                  capStep.name === currentStep
                    ? `${tokens.BRAND_HERO_BLUE}`
                    : `${tokens.TINTS_OFF_WHITE_20}`
                }
                lineHeight={20}
              >
                {capStep.name}
              </Typography>
            </CapNavItem>
          );
        })}
      </StepsList>
    </SidebarNav>
  );
};

const CapNavItem = styled(Button)`
  background: none;
  padding: 8px;
`;

const StepsList = styled.div`
  display: flex;
  gap: 24px;
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: row;
`;

export { CapNav };
