import { PayMethod } from "../types/payments-ui-form";
import { getEnv } from "../../utils/env";
import installPrepIcon from "components/assets/images/solar-steps/install-prep-icon.svg";
import checkoutIcon from "components/assets/images/solar-steps/checkout-icon.svg";
import siteSurveyIcon from "components/assets/images/solar-steps/site-survey-icon.svg";
import designReviewIcon from "components/assets/images/solar-steps/design-review-icon.svg";
import permittingIcon from "components/assets/images/solar-steps/permitting-icon.svg";
import installationIcon from "components/assets/images/solar-steps/installation-icon.svg";
import inspectionIcon from "components/assets/images/solar-steps/inspection-icon.svg";
import powerOnIcon from "components/assets/images/solar-steps/power-on-icon.svg";

const SOLAR_STEP_TITLES = {
  Checkout: "Checkout",
  Home_Review: "Home Review",
  Design_Approval: "Design Approval",
  Permitting: "City Permitting",
  Install_Prep: "Install Prep",
  Installation: "Installation",
  Inspection: "City Inspection",
  Power_On: "Power On",
};

const OPTY_PROGRESS_STEPS = [
  {
    stepTitle: "Step 1",
    description: SOLAR_STEP_TITLES.Checkout,
    img: checkoutIcon,
  },
  {
    stepTitle: "Step 2",
    description: SOLAR_STEP_TITLES.Home_Review,
    img: siteSurveyIcon,
  },
  {
    stepTitle: "Step 3",
    description: SOLAR_STEP_TITLES.Design_Approval,
    img: designReviewIcon,
  },
  {
    stepTitle: "Step 4",
    description: SOLAR_STEP_TITLES.Permitting,
    img: permittingIcon,
  },
  {
    stepTitle: "Step 5",
    description: SOLAR_STEP_TITLES.Install_Prep,
    img: installPrepIcon,
  },
  {
    stepTitle: "Step 6",
    description: SOLAR_STEP_TITLES.Installation,
    img: installationIcon,
  },
  {
    stepTitle: "Step 7",
    description: SOLAR_STEP_TITLES.Inspection,
    img: inspectionIcon,
  },
  {
    stepTitle: "Final Step",
    description: SOLAR_STEP_TITLES.Power_On,
    img: powerOnIcon,
  },
];

const CreditStatusPillLabels = {
  CREDIT_APPROVED: "Credit Approved",
  CREDIT_NOT_APPROVED: "Credit Not Approved",
  CREDIT_CHECK_IN_PROGRESS: "Credit Check in progress",
  CREDIT_NOT_CHECKED: "Credit Not Checked",
} as const;

const StatusMessage = {
  SUCCESS: "success",
  FAILURE: "failure",
  INFO: "info",
  WARNING: "warning",
} as const;

const PaymentMethods = new Map([
  [PayMethod.CHECKING, "Checking"],
  [PayMethod.SAVINGS, "Savings"],
]);

const experiencePaymentsUIUrl =
  getEnv() === "production"
    ? `https://payments-ui.sunrun.com`
    : `https://majstg-payments-ui.sunrun.com`;

const formatCurrency = (amount: number) => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export {
  SOLAR_STEP_TITLES,
  OPTY_PROGRESS_STEPS,
  CreditStatusPillLabels,
  StatusMessage,
  experiencePaymentsUIUrl,
  PaymentMethods,
  formatCurrency,
};
