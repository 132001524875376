import { tokens, Typography } from "@sunrun/experience-ui-components";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { useCapContext } from "../../providers/CapContext";
import { Column, Row } from "../atoms/layoutAtoms";
import { FinancialTermsRows } from "../molecules/FinancialTermRows";
import { SystemRows } from "../molecules/SystemRows";
import { ProductRows } from "../molecules/ProductRows";
import { ViewType } from "../../capTypes";

const DesignStepView: React.FC = () => {
  const {
    activeProposal,
    originalProposalDesignImages,
    activeProposalDesignImages,
    cap,
  } = useCapContext();

  const [viewType, setViewType] = useState<ViewType>("Single");

  let smallOriginalImage;
  if (originalProposalDesignImages) {
    smallOriginalImage = originalProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-small.jpg");
      }
    );
  }

  let smallProposalImage;
  if (activeProposalDesignImages) {
    smallProposalImage = activeProposalDesignImages.find(
      (image: { documentName: string | string[] }) => {
        return image.documentName.includes("design-image-small.jpg");
      }
    );
  }

  useEffect(() => {
    if (
      (cap?.type === "B" || cap?.type === "D") &&
      activeProposal?.parentProposalId
    ) {
      setViewType("Comparison");
    }
  }, [activeProposal?.parentProposalId, cap?.type]);

  return (
    <>
      <Typography
        color={tokens.BRAND_HERO_BLUE}
        style={{ fontSize: 32, marginTop: 40, marginBottom: 24 }}
      >
        Review your finalized design
      </Typography>

      {/*Single view*/}
      {viewType === "Single" && (
        <DesignCard>
          <DesignImage bgImageUrl={smallProposalImage?.url} />
          <TermsRow underline={false}>
            <TermsContainer>Specifications</TermsContainer>
          </TermsRow>
          <SystemRows viewType={viewType} />
          <ProductRows viewType={viewType} />
          <TermsRow underline={false}>
            <TermsContainer>Terms</TermsContainer>
          </TermsRow>
          <FinancialTermsRows viewType={viewType} />
        </DesignCard>
      )}

      {/*Comparison view*/}
      {viewType === "Comparison" && (
        <DesignCard>
          <DesignImageRow underline={false}>
            <DesignImageColumn>
              <Typography size={18} fontWeight={"medium"}>
                Original
              </Typography>
              <ComparisonDesignImage bgImageUrl={smallOriginalImage?.url} />
            </DesignImageColumn>
            <DesignImageColumn>
              <Typography size={18} fontWeight={"medium"}>
                Final Design
              </Typography>
              <ComparisonDesignImage bgImageUrl={smallProposalImage?.url} />
            </DesignImageColumn>
          </DesignImageRow>
          <TermsRow underline={false}>
            <TermsContainer>Specifications</TermsContainer>
          </TermsRow>
          <SystemRows viewType={viewType} />
          <ProductRows viewType={viewType} />
          <TermsRow underline={false}>
            <TermsContainer>Terms</TermsContainer>
          </TermsRow>
          <FinancialTermsRows viewType={viewType} />
        </DesignCard>
      )}
    </>
  );
};

const DesignImageRow = styled(Row)`
  gap: 20%;
`;
const DesignImageColumn = styled(Column)`
  align-items: center;

  & > p {
    padding: 8px;
    margin-bottom: 16px;
  }
`;

const DesignCard = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${tokens.BACKGROUND_LIGHT};
  border-radius: 8px;
  padding-top: 32px;
  padding-left: 64px;
  padding-right: 64px;
`;

const DesignImage = styled.div<{
  bgImageUrl: string;
}>`
  width: 100%;
  height: 443px;
  aspect-ratio: 4/3;
  ${({ bgImageUrl }) =>
    bgImageUrl ? `background: url(${bgImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 32px;
`;

const ComparisonDesignImage = styled.div<{
  bgImageUrl: string;
}>`
  display: flex;
  width: 100%;
  height: 240px;
  aspect-ratio: 3/2;
  ${({ bgImageUrl }) =>
    bgImageUrl ? `background: url(${bgImageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  margin-bottom: 32px;
`;

const TermsRow = styled(Row)`
  width: 100%;
  height: 100%;
  background: #f4f1ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px rgba(208, 204, 195, 0.6) solid;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  padding: 0;
`;

const TermsContainer = styled.div`
  flex: 1 1 0;
  text-align: center;
  color: #1f2647;
  font-size: 19px;
  font-family: "Roobert", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  margin: 0 !important;
  padding: 24px;
`;

export { DesignStepView };
