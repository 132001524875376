import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";

const CheckoutNavGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    tasks,
    tasksLoading,
    currentTask,
    currentTaskId,
    isRefetchingTasks,
    navigateToTask,
  } = useCheckoutContext();
  const [guardTriggered, setGuardTriggered] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (!tasksLoading && tasks?.length > 0) {
      if (currentTask && currentTask.isUnlocked) {
        setGuardTriggered(false);
      } else {
        setGuardTriggered(true);
      }
    }
  }, [tasks, currentTaskId, currentTask, tasksLoading]);

  //TODO: Add 'feature flag' guard

  // Only show loading indicator on first load, not on refetch. This allows for pages to show
  // more specific loading indicators instead of always being the entire screen loading.
  if ((tasksLoading && !isRefetchingTasks) || guardTriggered === undefined)
    return (
      <LoadingIndicator
        fullScreen
        color="black"
        message="Getting latest Checkout info..."
      />
    );

  if (guardTriggered) {
    // Redirect user to the first unlocked task.
    const firstUnlockedTask = tasks.find((task) => task.isUnlocked);

    if (!firstUnlockedTask) {
      // Should never happen
      throw new Error("No unlocked checkout step found.");
    }

    navigateToTask(firstUnlockedTask.id);
  }

  return <>{children}</>;
};

export { CheckoutNavGuard };
