import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styled from "@emotion/styled/macro";
import { EmblaOptionsType } from "embla-carousel";
import { SvgIcon, SvgNames, tokens } from "@sunrun/experience-ui-components";
import {
  ContentLeft,
  ContentRight,
  Row,
  RowRightContent,
} from "../atoms/layoutAtoms";
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import {
  BrandBlueButton,
  CarouselModal,
  CloseCarouselButton,
  ExpandImageButtonRow,
} from "../organisms/HomeReadinessStepView";
import { FullSizeCarousel } from "./FullSizeCarousel";

type PropType = {
  slides: ReactElement[];
  options?: EmblaOptionsType;
};

const CarouselWithThumbs: React.FC<PropType> = (props) => {
  const { slides, options } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  type ThumbProps = {
    selected: boolean;
    index: number;
    onClick: () => void;
  };

  const Thumb: React.FC<ThumbProps> = (props) => {
    const { selected, index, onClick } = props;

    return (
      <ThumbSlide
        className={"embla-thumbs__slide".concat(
          selected ? " embla-thumbs__slide--selected" : ""
        )}
      >
        <EmblaThumbsSlideContent
          onClick={onClick}
          type="button"
          className="embla-thumbs__slide__number"
        >
          {slides[index]}
        </EmblaThumbsSlideContent>
      </ThumbSlide>
    );
  };

  const EmblaThumbsSlideContent = styled.button`
    border-radius: 1.8rem;
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--detail-high-contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--thumbs-slide-height);
    width: 100%;
  `;

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();

    emblaMainApi.on("select", onSelect).on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaMainApi);

  const [fullImageIndex, setFullImageIndex] = useState(-1);

  const openLargeImageCarousel = useCallback((index: number) => {
    setFullImageIndex(index);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <NoPaddingRow underline={false}>
        <ContentLeft>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        </ContentLeft>
        <ContentRight>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </ContentRight>
      </NoPaddingRow>
      <Carousel>
        <Viewport ref={emblaMainRef}>
          <Container>
            {slides.map((content, index) => (
              <InnerSlide key={index}>
                {fullImageIndex === -1 && (
                  <ExpandImageButtonRow underline={false}>
                    <RowRightContent underline={false}>
                      <BrandBlueButton
                        style={{ zIndex: 1001, marginRight: 24 }}
                        onClick={(e) => openLargeImageCarousel(index)}
                      >
                        <SvgIcon
                          width={24}
                          height={24}
                          name={SvgNames.ArrowsOutSimple}
                          color={tokens.TINTS_OFF_WHITE_90}
                        />
                      </BrandBlueButton>
                    </RowRightContent>
                  </ExpandImageButtonRow>
                )}
                <SlideContent>{content}</SlideContent>
              </InnerSlide>
            ))}
          </Container>
        </Viewport>

        <Thumbs>
          <ThumbsViewport ref={emblaThumbsRef}>
            <ThumbsContainer>
              {slides.map((content, index) => (
                <Thumb
                  key={index}
                  onClick={() => onThumbClick(index)}
                  selected={index === selectedIndex}
                  index={index}
                />
              ))}
            </ThumbsContainer>
          </ThumbsViewport>
        </Thumbs>
      </Carousel>
      {fullImageIndex > -1 && (
        <CarouselModal
          customModalWidth={visualViewport?.width ?? 100}
          hideClose={true}
        >
          <RowRightContent underline={false}>
            <CloseCarouselButton onClick={() => setFullImageIndex(-1)}>
              <SvgIcon
                height={20}
                width={20}
                color={tokens.TINTS_OFF_WHITE_80}
                name={SvgNames.Close}
              />
            </CloseCarouselButton>
          </RowRightContent>
          <FullSizeCarousel
            requestedIndex={fullImageIndex}
            slides={slides}
            options={{ inViewThreshold: 1 }}
            showDots={true}
          />
        </CarouselModal>
      )}
    </>
  );
};

const Carousel = styled.div`
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
`;

const Viewport = styled.div`
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
`;

const InnerSlide = styled.div`
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
`;

const SlideContent = styled.div`
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
`;

const Thumbs = styled.div`
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 6rem;
  margin-top: var(--thumbs-slide-spacing);
`;

const ThumbsViewport = styled.div`
  overflow: hidden;
`;

const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
`;

const ThumbSlide = styled.div`
  flex: 0 0 22%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
`;

const NoPaddingRow = styled(Row)`
  margin: 0;
  padding: 0;

  & > div > button.embla__button--prev {
    margin-top: 13%;
    margin-left: 1%;
  }

  & > div > button.embla__button--next {
    margin-top: 13%;
    margin-right: 4%;
  }
`;

export { CarouselWithThumbs };
