import styled from "@emotion/styled/macro";
import { ReactElement } from "react";
import { tokens } from "@sunrun/experience-ui-components";
import { EditOfferProduct } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { HorizontalLine } from "components/atoms/HorizontalLine";

type SubOptionsValue = {
  [key: string]: string[];
};

type SubOptionsType = {
  name: string;
  values: SubOptionsValue;
};

type UpgradesGroupOptionsProps = {
  groupValue: string;
  subOptions: SubOptionsType;
  selectedVariant: EditOfferProduct["variants"][0];
  setOption: (name: string, value: string) => void;
  disabled: boolean;
};
const UpgradesGroupOptions = ({
  groupValue,
  subOptions,
  selectedVariant,
  setOption,
  disabled,
}: UpgradesGroupOptionsProps) => {
  if (!subOptions?.values[groupValue]) {
    return null;
  }

  return (
    <UpgradeOptionsRadioGroup data-testid={`${groupValue}-subOptions`}>
      {subOptions.values[groupValue].map((subOptionValue) => {
        return (
          <RadioGroupLabel
            key={`${groupValue}${subOptionValue.toLowerCase()}`}
            htmlFor={`${groupValue}${subOptionValue}`}
          >
            <UpgradeRadioButton
              type="radio"
              name={groupValue}
              id={`${groupValue}${subOptionValue}`}
              value={subOptionValue}
              checked={
                selectedVariant!.selectedOptions.findIndex(
                  (option) => option.value === subOptionValue
                ) !== -1
              }
              onChange={() => setOption(subOptions.name, subOptionValue)}
              disabled={disabled}
              data-testid={"standard-radio"}
            />
            <div>{subOptionValue}</div>
          </RadioGroupLabel>
        );
      })}
    </UpgradeOptionsRadioGroup>
  );
};

type IncludedProductRadioButtonProps = {
  selected: boolean;
  groupName: string;
  groupValue: string;
  label: ReactElement;
  disabled: boolean;
  subOptions?: SubOptionsType;
  selectedVariant: EditOfferProduct["variants"][0];
  setOption: (name: string, value: string) => void;
};

const IncludedProductRadioButton = ({
  selected,
  groupName,
  groupValue,
  label,
  subOptions,
  selectedVariant,
  setOption,
  disabled,
}: IncludedProductRadioButtonProps) => {
  return (
    <>
      <IncludedProductOptionsRadioWrapper>
        <RadioGroupLabel>
          <RadioInput
            type="radio"
            name={groupName.toLowerCase().replaceAll(" ", "-")}
            aria-label={groupValue}
            value={groupValue}
            checked={selected}
            onChange={() => setOption(groupName, groupValue)}
            disabled={disabled}
            data-testid={"standard-radio"}
          />
          {label}
        </RadioGroupLabel>
      </IncludedProductOptionsRadioWrapper>
      {subOptions && selected && (
        <>
          <UpgradesGroupOptions
            groupValue={groupValue}
            subOptions={subOptions}
            selectedVariant={selectedVariant}
            setOption={setOption}
            disabled={disabled}
          />
          <HorizontalLine />
        </>
      )}
    </>
  );
};

const IncludedProductOptionsRadioWrapper = styled.div`
  margin-bottom: 15px;
`;
const RadioGroupLabel = styled.label`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;
const RadioInput = styled.input`
  cursor: pointer;
`;
const UpgradeOptionsRadioGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 15px;
`;
const UpgradeRadioButton = styled.input`
  opacity: 0;
  position: absolute;
  & + div {
    padding: 4px 14px;
    border: 1px solid ${tokens.BRAND_HERO_BLUE};
    background: ${tokens.BRAND_OFF_WHITE};
    gap: 8px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }
  &:checked + div,
  &:hover + div {
    background-color: ${tokens.BRAND_HERO_BLUE};
    color: white;
  }
  &:checked + div div,
  &:hover + div div {
    color: white;
  }
`;

export type { IncludedProductRadioButtonProps };
export { IncludedProductRadioButton };
