import {
  Button,
  Card,
  CardHeader,
  Pill,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { JSX } from "react/jsx-runtime";
import { useCapContext } from "../../providers/CapContext";

import { AutopayEnrollmentForm } from "../molecules/AutopayEnrollmentForm";
import { formatCurrency } from "../../../checkout/util/constants";
import { RadioCard } from "../../../checkout/components/atoms/RadioCard";
import { PayMethod } from "../../../checkout/types/payments-ui-form";
import { CapDoc } from "../../capTypes";
import { ContentLeft, ContentRight, Flex } from "../atoms/layoutAtoms";

const ReadSignStepView: React.FC = () => {
  const {
    activeProposal,
    documents,
    prospectId,
    prospect,
    contacts,
    sendDocumentEmail,
  } = useCapContext();

  const [showAutopaySection, setShowAutopaySection] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showAutopayForm, setShowAutopayForm] = useState(false);
  const [showAutopaySuccess, setShowAutopaySuccess] = useState(false);
  const [autopaySuccessResponse, setAutopaySuccessResponse] =
    useState(undefined);
  const [paymentType, setPaymentType] = useState(PayMethod.CHECKING);
  const AUTOPAY_LINK = `https://www.sunrun.com/autopay`;

  const isAutopayEnrolled =
    activeProposal?.billingMethod === "Recurring" || autopaySuccessResponse;

  useEffect(() => {
    if (activeProposal) {
      if (
        activeProposal.dealType === "FlexMonthly PPA" ||
        activeProposal.dealType === "FlexMonthly" ||
        activeProposal.dealType === "monthly"
      ) {
        setShowAutopaySection(true);

        if (isAutopayEnrolled) {
          setShowAutopaySuccess(true);
        } else {
          setShowBankForm(true);
        }
      }
    }
  }, [isAutopayEnrolled, activeProposal, autopaySuccessResponse]);

  useEffect(() => {
    if (showAutopayForm) {
      setShowBankForm(false);
      setShowAutopaySuccess(false);
    }
  }, [showAutopayForm]);

  let monthlyPayment: string | undefined;
  if (activeProposal) {
    switch (activeProposal.dealType) {
      case "monthly":
      case "Monthly":
        monthlyPayment = formatCurrency(activeProposal.monthlySunrunBill);
        break;
      case "FlexMonthly":
        monthlyPayment = `${formatCurrency(
          activeProposal.monthlySunrunBill
        )} - ${formatCurrency(
          activeProposal.monthlySunrunBill +
            activeProposal.monthlySunrunBill * activeProposal.actualEscalator
        )}`;
        break;
    }
  }

  const PaymentTypeRadioButtons: JSX.Element[] = [];
  const createRadioOptionsFromPaymentMethods = (
    capitalizedLabel: string,
    valueForEnum: string
  ) => {
    PaymentTypeRadioButtons.push(
      <RadioCard
        key={valueForEnum}
        labelElement={
          <LabelContainer role="label">
            <Typography
              size={tokens.FONT_SIZE_3}
              color={tokens.BRAND_HEROBLUE}
              fontWeight="medium"
            >
              {capitalizedLabel}{" "}
            </Typography>
            <Typography
              color={tokens.TINTS_OFF_WHITE_20}
              size={tokens.FONT_SIZE_3}
            >
              Recurring bank transfer
            </Typography>
          </LabelContainer>
        }
        checked={paymentType === valueForEnum}
        handleChange={() => setPaymentType(valueForEnum as PayMethod)}
      />
    );
  };

  const PaymentMethods = new Map([
    [PayMethod.CHECKING, "Checking"],
    [PayMethod.SAVINGS, "Savings"],
  ]);

  PaymentMethods.forEach(createRadioOptionsFromPaymentMethods);

  return (
    <AgreementsContainer>
      <Row>
        <DocSidebar>
          <Typography variant="h4">Sign your documents</Typography>
          <Typography>We are excited to work with you!</Typography>
        </DocSidebar>

        {documents && prospect && (
          <DocumentsContainer>
            {documents.map((doc: CapDoc) => {
              return (
                <CapDocument>
                  <CardHeader>
                    <ContentLeft>
                      <Typography
                        color={tokens.BRAND_HERO_BLUE}
                        size={20}
                        fontWeight={"medium"}
                      >
                        {doc.documentType === "CAP Package"
                          ? "Design approval agreement"
                          : doc.documentType}
                      </Typography>
                    </ContentLeft>
                    {doc.customerSigned === "Customer Signed" ? (
                      <ContentRight>
                        <Pill type="success" text="Signed"></Pill>
                      </ContentRight>
                    ) : (
                      <ContentRight>
                        <Pill
                          type="info"
                          icon={SvgNames.PenToolSvg}
                          text="Ready to Sign"
                        />
                      </ContentRight>
                    )}
                  </CardHeader>

                  {doc.documentType === "CAP Package" && (
                    <Typography>
                      Please review the terms of your solar agreement with
                      Sunrun
                    </Typography>
                  )}
                  <Flex>
                    <ContentLeft>
                      <a href={doc.documentLink}>Read</a>
                    </ContentLeft>
                    <ContentRight>
                      <EmailButton
                        onClick={() => sendDocumentEmail(doc, prospectId ?? "")}
                      >
                        Email
                      </EmailButton>
                    </ContentRight>
                  </Flex>
                </CapDocument>
              );
            })}
          </DocumentsContainer>
        )}
      </Row>
      {showAutopaySection && (
        <Row className="autopay-row">
          <AutopaySidebar>
            <Typography variant="h4">Enroll in autopay</Typography>
            <Typography>
              Your quoted first year monthly bill includes the discount you
              receive when you sign up for ACH autopay.
            </Typography>
            <Typography>
              For exact discount details, please refer to your customer
              agreement. If you have questions, please visit{" "}
              <a href={AUTOPAY_LINK} rel="noreferrer noopener" target="_blank">
                www.sunrun.com/autopay
              </a>
            </Typography>
          </AutopaySidebar>
          {prospect && contacts && (
            <AutopayContainer>
              {!isAutopayEnrolled && showBankForm && (
                <Card>
                  <CardHeader>
                    <Typography size={19} fontWeight={"medium"}>
                      Autopay Details
                    </Typography>
                  </CardHeader>
                  <SmashedPumpkins>
                    <Typography
                      size={16}
                      fontWeight={"medium"}
                      color={tokens.BRAND_HERO_BLUE}
                    >
                      Autopay amount
                    </Typography>
                    <Typography
                      size={28}
                      fontWeight={"medium"}
                      color={tokens.BRAND_HERO_BLUE}
                    >
                      {monthlyPayment}/mo
                    </Typography>
                    <Typography
                      size={14}
                      color={tokens.OFFWHITE_10}
                      fontWeight={"regular"}
                    >
                      Price includes discount for enrolling in ACH Autopay
                    </Typography>
                  </SmashedPumpkins>
                  {!showAutopaySuccess && showBankForm && (
                    <FlexGap>
                      <Typography color={tokens.BRAND_HERO_BLUE}>
                        Payment Method
                      </Typography>
                      {PaymentTypeRadioButtons.map(
                        (PaymentTypeRadioButton) => PaymentTypeRadioButton
                      )}
                      <ContinueAutopayButton
                        onClick={() => setShowAutopayForm(true)}
                      >
                        Continue with autopay
                      </ContinueAutopayButton>
                    </FlexGap>
                  )}
                </Card>
              )}
              <AutopayEnrollmentForm
                autopayPaymentAmount={monthlyPayment?.toString() ?? ""}
                prospect={prospect}
                contacts={contacts}
                paymentType={paymentType}
                showForm={showAutopayForm}
                setShowForm={setShowAutopayForm}
                showBankForm={showBankForm}
                showSuccess={showAutopaySuccess}
                setSuccessResponse={(data) => setAutopaySuccessResponse(data)}
              />
            </AutopayContainer>
          )}
        </Row>
      )}
    </AgreementsContainer>
  );
};

const DocSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 12px;
`;

const AutopaySidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 12px;
`;

const FlexGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContinueAutopayButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  padding: 8px 48px;
`;

const CapDocument = styled(Card)``;

const Row = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const EmailButton = styled(Button)`
  padding: 8px 48px;
`;

const DocumentsContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SmashedPumpkins = styled(Card)`
  border: unset;

  & > p {
    margin: unset !important;
  }
`;

const AutopayContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const AgreementsContainer = styled.section``;

export { ReadSignStepView };
