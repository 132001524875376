import React from "react";
import { Card, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { Row } from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { CarouselWithThumbs } from "../molecules/CarouselWithThumbs";
import { Slide } from "./HomeReadinessStepView";

const EquipmentLocationStepView: React.FC = () => {
  const { sow } = useCapContext();

  const lineItemImages = sow?.allSharinPixImages.filter((image: any) => {
    return (
      image.sharinpix__DisplayTags__c?.includes("CAP Design Summary") &&
      image.sharinpix__DisplayTags__c?.includes("Equipment")
    );
  });

  const slides = lineItemImages?.map((image: any, index: number) => {
    return (
      <StyledSlide
        imageUrl={image.sharinpix__ImageURLFull__c}
        key={index}
      ></StyledSlide>
    );
  });

  return (
    <>
      <EquipLoc underline={false}>
        <EquipmentSidebar>
          <Typography variant="h4">Equipment installation plan</Typography>
          <Typography>
            Your installation will require some equipment to be mounted on a
            wall, often near your main electrical panel or electric meter. These
            photos show where the equipment will likely be placed. On
            installation day, your foreperson will review the final locations
            with you.
          </Typography>
        </EquipmentSidebar>
        <ImagesContainer>
          {slides && slides.length > 0 && (
            <Card>
              <CarouselWithThumbs slides={slides}></CarouselWithThumbs>
            </Card>
          )}
        </ImagesContainer>
      </EquipLoc>
    </>
  );
};

const EquipLoc = styled(Row)`
  margin-top: 16px;
  gap: 32px;
  align-items: unset;
`;

const EquipmentSidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 12px;
`;

const ImagesContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledSlide = styled(Slide)`
  width: 100%;
`;

export { EquipmentLocationStepView };
