import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { rollbar } from "../../providers/rollbar";
import { useAppSelector } from "../../store";
import { useCap, useCapDocs, useSow } from "../hooks/useCapSwr";
import { Cap, CapDoc, CapType, Proposal, Sow } from "../capTypes";
import {
  SplatContact,
  SplatProposal,
} from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useContactsData, useProposals } from "../../utils/swrHooks";
import {
  useCheckoutAttachments,
  useProspect,
} from "../../checkout/hooks/useCheckoutSwr";
import { postDocumentEmail } from "../api/capApi";

interface CapContextType {
  cap: Cap | undefined;
  documents: CapDoc[] | undefined;
  prospectId: string | undefined;
  prospect: any | undefined;
  contacts: SplatContact[] | undefined;
  sow: Sow | undefined;
  originalProposal: SplatProposal | undefined;
  sendDocumentEmail: (document: CapDoc, prospectId: string) => void;
  activeProposal: Proposal | undefined;
  activeProposalDesignImages: any;
  originalProposalDesignImages: any;
}

const CapContext = createContext<CapContextType>({
  cap: undefined,
  documents: [],
  prospectId: undefined,
  prospect: undefined,
  contacts: undefined,
  sow: undefined,
  originalProposal: undefined,
  sendDocumentEmail: () => {},
  activeProposal: undefined,
  activeProposalDesignImages: undefined,
  originalProposalDesignImages: undefined,
});

const useCapContext = (): CapContextType => {
  const context = useContext(CapContext);
  if (!context) {
    rollbar.error(
      "[<CapContext>] -- useCapContext must be used within a CapContextProvider"
    );
    throw new Error("useCapContext must be used within a CapContextProvider");
  }
  return context;
};

const CapContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const { data: prospect } = useProspect(prospectId);
  const { cap } = useCap(authKey, prospectId);
  const capTask = cap?.salesCapTasks?.find?.(
    (task: any) => task.status !== "Inactive"
  );
  const capName = capTask?.name;
  const capLetterType = capName?.match(/\(([A-Z])\)$/)?.[1] as CapType;
  console.log(capLetterType);

  if (cap) {
    cap.type = capLetterType;
  }

  const { contacts } = useContactsData(prospectId ?? "", authKey);
  const { documents } = useCapDocs(authKey, prospectId);
  const { sow } = useSow(authKey, prospectId ?? "");
  const activeProposal = sow?.proposal;
  const { data: activeProposalDesignImages } = useCheckoutAttachments(
    prospectId!,
    sow?.proposalName!,
    "Design Image"
  );

  const { data: proposals } = useProposals(prospectId!, authKey);

  const findOriginalProposal = () => {
    if (proposals?.length! > 0 && sow?.proposal.parentProposalId) {
      return proposals?.filter((p: SplatProposal) => {
        return p.proposalId === sow.proposal.parentProposalId;
      })[0];
    }
  };

  const originalProposal = findOriginalProposal();

  const { data: originalProposalDesignImages } = useCheckoutAttachments(
    prospectId!,
    originalProposal?.proposalId ?? "",
    "Design Image"
  );

  const capContextValue = useMemo(() => {
    const sendDocumentEmail = async (document: any, prospectId: string) => {
      const documentEmail = {
        primaryContactId:
          contacts?.filter((c: SplatContact) => c.primary)[0].contactId ?? "",
        customerSigned: document.customerSigned,
        documentType: document.documentType,
        srAttachmentId: document.documentId,
      };
      await postDocumentEmail(authKey, documentEmail, prospectId);
    };

    return {
      cap,
      prospectId,
      prospect,
      contacts,
      documents,
      sow,
      originalProposal,
      sendDocumentEmail,
      activeProposal,
      activeProposalDesignImages,
      originalProposalDesignImages,
    } as CapContextType;
  }, [
    cap,
    prospectId,
    prospect,
    contacts,
    documents,
    sow,
    originalProposal,
    activeProposal,
    activeProposalDesignImages,
    originalProposalDesignImages,
    authKey,
  ]);

  return (
    <CapContext.Provider value={capContextValue}>
      {children}
    </CapContext.Provider>
  );
};

export { useCapContext, CapContextProvider };
export type { CapContextType };
