import React, { ReactNode, useEffect } from "react";
import { EmblaOptionsType } from "embla-carousel";
import styled from "@emotion/styled/macro";
import useEmblaCarousel from "embla-carousel-react";
import {
  DotButton,
  Embla,
  EmblaContainer,
  EmblaDots,
  EmblaSlide,
  EmblaViewport,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import { ContentLeft, ContentRight, Row } from "../atoms/layoutAtoms";

type PropType = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  showDots?: boolean;
  requestedIndex: number;
};

const FullSizeCarousel: React.FC<PropType> = (props) => {
  const { slides, requestedIndex, showDots } = props;
  const [ref, api] = useEmblaCarousel({});

  useEffect(() => {
    if (requestedIndex > -1) {
      api?.scrollTo(requestedIndex);
    }
  }, [api, requestedIndex]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(api);

  return (
    <>
      <NoPaddingRow underline={false}>
        <ContentLeft>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        </ContentLeft>
        <ContentRight>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </ContentRight>
      </NoPaddingRow>
      <Embla>
        <EmblaViewport ref={ref}>
          <EmblaContainer>
            {slides.map((child, index) => (
              <EmblaSlide key={index}>{child}</EmblaSlide>
            ))}
          </EmblaContainer>
        </EmblaViewport>

        {!!showDots ?? (
          <EmblaDots>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex
                    ? " embla__dot--selected full-size"
                    : " full-size"
                )}
              />
            ))}
          </EmblaDots>
        )}
      </Embla>
    </>
  );
};

const NoPaddingRow = styled(Row)`
  padding: 0;

  & > div > button.embla__button--prev {
    margin-top: 25%;
  }

  & > div > button.embla__button--next {
    margin-top: 25%;
  }
`;

export { FullSizeCarousel };
