import styled from "@emotion/styled/macro";
import { EmblaCarouselType } from "embla-carousel";
import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SvgIcon, SvgNames, tokens } from "@sunrun/experience-ui-components";

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = ComponentPropsWithRef<"button">;

const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <PrevStyledButton
      className="embla__button--prev"
      type="button"
      {...restProps}
    >
      <SvgIcon
        name={SvgNames.ChevronLeftFilled}
        width={35}
        height={35}
        color={tokens.TINTS_OFF_WHITE_90}
      />
      {children}
    </PrevStyledButton>
  );
};

const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <NextStyledButton
      className="embla__button--next"
      type="button"
      {...restProps}
    >
      <SvgIcon
        name={SvgNames.ChevronRightFilled}
        width={35}
        height={35}
        color={tokens.TINTS_OFF_WHITE_90}
      />
      {children}
    </NextStyledButton>
  );
};

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

const useDotButton = (
  emblaApi: EmblaCarouselType | undefined
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;
  const isFullSize = !!restProps.className?.includes("full-size");
  const isActive = !!restProps.className?.includes("embla__dot--selected");
  return (
    <EnblaDot
      isFullSizeMode={isFullSize}
      isActive={isActive}
      type="button"
      {...restProps}
    >
      {children}
    </EnblaDot>
  );
};

const EnblaDot = styled.button<{
  isActive: boolean;
  isFullSizeMode: boolean;
}>`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  background-color: ${tokens.TINTS_MEDIUM_BLUE_60};
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  margin-left: 3px;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({ isActive, isFullSizeMode }) =>
    isActive
      ? !isFullSizeMode
        ? `border: 4px solid ${tokens.BRAND_HERO_BLUE}`
        : `border: 4px solid white`
      : 0}
`;

const EmblaButton = styled.button`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: ${tokens.TINTS_OFF_WHITE_90};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrevStyledButton = styled(EmblaButton)`
  position: absolute;
  margin-top: 8%;
`;

const NextStyledButton = styled(EmblaButton)`
  position: absolute;
  margin-top: 8%;
`;
const Embla = styled.section`
  --slide-height: 19rem;
  --slide-spacing: calc(10%);
  --slide-size: calc(90%);
  --slide-spacing-sm: calc(10%);
  --slide-size-sm: 100%;
  --slide-spacing-lg: calc(10%);
  --slide-size-lg: calc(90%);
`;
const EmblaViewport = styled.div`
  overflow: hidden;
  min-height: 200px;
`;
const EmblaContainer = styled.div`
  display: flex;
  gap: 10%;
  touch-action: pan-y pinch-zoom;
`;

const EmblaSlide = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;

  &:first-of-type {
    padding-left: 5%;
  }
`;

const EmblaDots = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Embla,
  EmblaDots,
  EmblaViewport,
  EmblaSlide,
  EmblaContainer,
  NextButton,
  PrevButton,
  usePrevNextButtons,
  EmblaButton,
  useDotButton,
  DotButton,
};
