import {
  Button,
  Modal,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import { formatCurrency } from "../../../utils/formatHelpers";
import { useCapContext } from "../../providers/CapContext";
import {
  ContentLeft,
  ContentRight,
  Row,
  RowRightContent,
} from "../atoms/layoutAtoms";
import { SimpleCarousel } from "../molecules/SimpleCarousel";
import { FullSizeCarousel } from "../molecules/FullSizeCarousel";
import { SowLineItemDescriptions } from "../../constants/sowLineItemDescription";
import { LineItem, WorkItem, WorkType } from "../../capTypes";

interface WorkItemsProps {
  workItem: WorkItem;
}

interface LineItemProps {
  lineItem: LineItem;
}

const SUNRUN_MANAGED = "Sunrun managed";
const CUSTOMER_MANAGED = "Customer managed";

const WorkLineItem = ({ lineItem }: LineItemProps): React.JSX.Element => {
  const isCustomerManaged = lineItem?.line_item_assignment
    ?.toLowerCase()
    .includes("customer managed");

  const [showLineItemDetailModal, setShowLineItemDetailModal] = useState(false);

  const lineItemPrettyDescription = SowLineItemDescriptions.map((d) => {
    return d[lineItem.line_item_subType]?.description ?? "";
  });

  return (
    <SowLineItem>
      <SingleSpacedRow underline={false}>
        <LineItemLeft>
          <Typography size={20} fontWeight={"medium"}>
            {lineItem.line_item_subType}
          </Typography>
          <ClearButton onClick={() => setShowLineItemDetailModal(true)}>
            <SvgIcon
              name={SvgNames.InfoCircle}
              width={24}
              height={24}
              color={tokens.BRAND_HERO_BLUE}
            />
          </ClearButton>
        </LineItemLeft>
        <LineItemRight>
          <ContentLeft>
            {isCustomerManaged ? (
              <CustomerChip>
                <Typography
                  color={tokens.TINTS_OFF_WHITE_90}
                  size={16}
                  fontWeight={"medium"}
                  lineHeight={19}
                >
                  {CUSTOMER_MANAGED}
                </Typography>
              </CustomerChip>
            ) : (
              <SunrunChip>
                <Typography
                  color={tokens.BRAND_HERO_BLUE}
                  size={16}
                  fontWeight={"medium"}
                  lineHeight={19}
                >
                  {SUNRUN_MANAGED}
                </Typography>
              </SunrunChip>
            )}
          </ContentLeft>
          <ContentRight>
            <Typography color={tokens.TINTS_OFF_WHITE_20} size={24}>
              {formatCurrency(lineItem.line_item_price)}
            </Typography>
          </ContentRight>
        </LineItemRight>
      </SingleSpacedRow>
      <SingleSpacedRow underline={false}>
        <ContentLeft>
          <Typography>{lineItemPrettyDescription}</Typography>
        </ContentLeft>
        <ContentRight>
          <span></span>
        </ContentRight>
      </SingleSpacedRow>
      {showLineItemDetailModal && (
        <Modal
          hideIcon={true}
          title="Detailed information"
          onClose={() => setShowLineItemDetailModal(false)}
        >
          <Typography
            size={20}
            fontWeight={"medium"}
            style={{ marginBottom: 24 }}
          >
            Technical information
          </Typography>
          <Typography style={{ marginBottom: 24 }}>
            {lineItem.line_item_description}
          </Typography>
          <Typography
            size={20}
            fontWeight="medium"
            style={{ marginBottom: 24 }}
          >
            Instructions
          </Typography>
          <Typography>{lineItem.line_item_instructions}</Typography>
        </Modal>
      )}
    </SowLineItem>
  );
};

const WorkItemAccordion = ({ workItem }: WorkItemsProps): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [fullImageIndex, setFullImageIndex] = useState(-1);

  const { sow } = useCapContext();

  const mapItemTypeToTagName = (type: WorkType) => {
    const tagItemMap = {
      Electrical: "Electrical",
      Roofing: "Roof",
      Structural: "Structural",
      Misc: "Miscellaneous",
      "Post Install Repair": "",
    };
    return tagItemMap[type];
  };

  const lineItemImages = sow?.allSharinPixImages.filter((image: any) => {
    return (
      image.sharinpix__DisplayTags__c?.includes("CAP Design Summary") &&
      image.sharinpix__DisplayTags__c?.includes(
        mapItemTypeToTagName(workItem?.type ?? "")
      )
    );
  });

  const openLargeImageCarousel = useCallback((index: number) => {
    setFullImageIndex(index);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const slides = lineItemImages?.map((image: any, index: number) => {
    return (
      <Slide imageUrl={image.sharinpix__ImageURLFull__c} key={index}>
        {fullImageIndex === -1 && (
          <ExpandImageButtonRow underline={false}>
            <RowRightContent underline={false}>
              <BrandBlueButton onClick={(e) => openLargeImageCarousel(index)}>
                <SvgIcon
                  width={24}
                  height={24}
                  name={SvgNames.ArrowsOutSimple}
                  color={tokens.TINTS_OFF_WHITE_90}
                />
              </BrandBlueButton>
            </RowRightContent>
          </ExpandImageButtonRow>
        )}
      </Slide>
    );
  });

  return (
    <FullPanel>
      <PanelHeader underline={true}>
        <ContentLeft>
          <Typography size={24} fontWeight={"regular"} lineHeight={31}>
            {workItem.type}
          </Typography>
        </ContentLeft>
        <ContentRight>
          <Button
            onClick={() => setIsExpanded(true)}
            style={{
              background: "none",
              padding: 0,
              display: `${!isExpanded ? "flex" : "none"}`,
            }}
          >
            <SvgIcon
              height={30}
              width={30}
              color={tokens.BRAND_HERO_BLUE}
              name={SvgNames.ChevronDown}
            />
          </Button>
          <Button
            onClick={() => setIsExpanded(false)}
            style={{
              background: "none",
              padding: 0,
              display: `${isExpanded ? "flex" : "none"}`,
            }}
          >
            <SvgIcon
              height={30}
              width={30}
              color={tokens.BRAND_HERO_BLUE}
              name={SvgNames.ChevronUp}
            />
          </Button>
        </ContentRight>
      </PanelHeader>
      {isExpanded && (
        <Panel>
          {workItem.lineItems.map((item) => (
            <WorkLineItem lineItem={item} />
          ))}
          {slides && slides.length > 0 && (
            <SimpleCarousel slides={slides} showDots={true} />
          )}
        </Panel>
      )}
      {slides && fullImageIndex > -1 && (
        <CarouselModal
          customModalWidth={visualViewport?.width ?? 100}
          hideClose={true}
        >
          <RowRightContent underline={false}>
            <CloseCarouselButton onClick={() => setFullImageIndex(-1)}>
              <SvgIcon
                height={20}
                width={20}
                color={tokens.TINTS_OFF_WHITE_80}
                name={SvgNames.Close}
              />
            </CloseCarouselButton>
          </RowRightContent>
          <FullSizeCarousel
            requestedIndex={fullImageIndex}
            slides={slides}
            options={{ inViewThreshold: 1 }}
            showDots={true}
          />
        </CarouselModal>
      )}
    </FullPanel>
  );
};

const HomeReadinessStepView: React.FC = () => {
  const { sow } = useCapContext();

  let filteredWorkItems: WorkItem[] = [];

  sow?.workItems.forEach((workItem: WorkItem) => {
    if (workItem.lineItems.length > 0) {
      filteredWorkItems.push(workItem);
    }
  });

  return (
    <>
      {sow && (
        <>
          <Typography
            color={tokens.BRAND_HERO_BLUE}
            style={{ fontSize: 32, marginTop: 40, marginBottom: 24 }}
          >
            Getting your home ready for solar
          </Typography>

          {filteredWorkItems.map((item) => {
            return (
              <WorkItemAccordion
                workItem={item}
                key={item.type.toLowerCase()}
              />
            );
          })}
          <SummaryRow
            underline={false}
            style={{ paddingTop: "8px", borderTop: "1px solid #1F2647" }}
          >
            <ContentLeft>
              <Typography fontWeight={"regular"} size={20}>
                Subtotal work needed
              </Typography>
            </ContentLeft>
            <ContentRight>
              <Typography color={"#6F6D68"} fontWeight={"medium"} size={24}>
                {formatCurrency(sow.priceOfAllWork + sow.totalTax, 2)}
              </Typography>
            </ContentRight>
          </SummaryRow>
          <SummaryRow underline={false}>
            <ContentLeft>
              <Typography color={"#C37354"} fontWeight={"medium"} size={20}>
                Sunrun subsidy
              </Typography>
            </ContentLeft>
            <ContentRight>
              <Typography color={"#C37354"} fontWeight={"medium"} size={20}>
                -{formatCurrency(sow.totalSubsidy, 2)}
              </Typography>
            </ContentRight>
          </SummaryRow>
          <SummaryRow underline={false}>
            <ContentLeft>
              <Typography fontWeight={"regular"} size={24}>
                Total cost to customer
              </Typography>
            </ContentLeft>
            <ContentRight>
              <Typography fontWeight={"regular"} size={24}>
                {formatCurrency(sow.priceToCustomer, 2)}
              </Typography>
            </ContentRight>
          </SummaryRow>
        </>
      )}
    </>
  );
};

const FullPanel = styled.section`
  margin-bottom: 15px;
`;

const SunrunChip = styled.span`
  line-height: 20px;
  height: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid ${tokens.BRAND_HERO_BLUE};
`;

const CustomerChip = styled.span`
  line-height: 20px;
  height: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: ${tokens.BRAND_HERO_BLUE};
`;

const PanelHeader = styled(Row)`
  background: ${tokens.TINTS_OFF_WHITE_90};
  border-radius: 8px;
  border: 1px rgba(208, 204, 195, 0.6) solid;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  width: auto;
  padding: 20px 24px;
`;

const Panel = styled.div`
  min-height: 100px;
  background: ${tokens.TINTS_OFF_WHITE_90};
  padding: 16px 24px;
`;

const Slide = styled.div<{
  imageUrl: string;
}>`
  aspect-ratio: 3/2;
  ${({ imageUrl }) =>
    imageUrl ? `background: url(${imageUrl}) no-repeat center;` : ""}
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
`;

const SummaryRow = styled(Row)`
  padding: 0;
`;

const SingleSpacedRow = styled(Row)`
  padding-top: 0;
  padding-bottom: 0;
`;

const SowLineItem = styled.section`
  margin-bottom: 8px;
`;

const LineItemLeft = styled(ContentLeft)`
  display: flex;
  gap: 10px;
  width: 60%;
`;

const LineItemRight = styled(ContentRight)`
  width: 40%;
`;

const ClearButton = styled(Button)`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
`;

const ExpandImageButtonRow = styled(Row)`
  padding: 0;
  padding-right: 8px;
`;

const BrandBlueButton = styled(Button)`
  background: ${tokens.BRAND_HERO_BLUE};
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
  margin: 0;
  margin-right: 8px;
`;

const CarouselModal = styled(Modal)`
  margin: 0;
  background: rgba(0, 0, 0, 0.8);

  .modal-container {
    top: 0;
    width: 100%;
    height: 100%;

    border: 0;
    background-color: rgba(0, 0, 0, 0.8);

    & > div {
      padding: 0;
      background-color: transparent;
    }
  }

  .modal-content-container {
    background-color: transparent;

    margin-bottom: 32px;
  }
`;

const CloseCarouselButton = styled(Button)`
  background: transparent;
  border: 0;
  color: white;
`;

export {
  HomeReadinessStepView,
  BrandBlueButton,
  ExpandImageButtonRow,
  Slide,
  CarouselModal,
  CloseCarouselButton,
};
