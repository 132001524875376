import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";

type SlimContactCardProps = {
  contact: SplatContact;
  secondaryContact?: SplatContact;
  showEmail?: boolean;
  label?: string;
  onClick?: () => void;
  buttonLabel?: string;
};

const SlimContactCard = ({
  contact,
  secondaryContact,
  showEmail,
  onClick,
  label,
  buttonLabel,
  children,
}: React.PropsWithChildren<SlimContactCardProps>) => {
  const PRIMARY_NAME = `${contact.customerFirstName} ${contact.customerLastName} `;
  const PRIMARY_EMAIL = ` ${contact.customerEmail}`;
  const SECONDARY_NAME = ` & ${secondaryContact?.customerFirstName} ${secondaryContact?.customerLastName}`;
  const SECONDARY_EMAIL = ` ${secondaryContact?.customerEmail}`;
  const ContactNames = () => {
    return (
      <>
        <Typography variant="span" fontWeight={showEmail ? "bold" : "regular"}>
          {PRIMARY_NAME}
        </Typography>
        {showEmail && <Typography variant="span">{PRIMARY_EMAIL}</Typography>}
        {secondaryContact!! && (
          <Typography
            fontWeight={showEmail ? "bold" : "regular"}
            variant="span"
          >
            {SECONDARY_NAME}
          </Typography>
        )}
        {secondaryContact!! && showEmail && (
          <Typography variant="span">{SECONDARY_EMAIL}</Typography>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <Text>
        {label && (
          <Typography
            color={tokens.BRAND_HERO_BLUE}
            size={tokens.FONT_SIZE_3}
            testId="slim-contact-card-label"
          >
            {label}
          </Typography>
        )}
        <Typography
          color={tokens.TINTS_OFF_WHITE_20}
          size={tokens.FONT_SIZE_3}
          testId="slim-contact-card-contact-names"
        >
          <ContactNames />
        </Typography>
      </Text>
      {children ? (
        children
      ) : (
        <HyperlinkButton onClick={onClick}>{buttonLabel}</HyperlinkButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 24px;
  border-radius: 8px;
  border: 1px solid ${tokens.TINTS_OFF_WHITE_40};
  background: ${tokens.TINTS_OFF_WHITE_70};
`;

const Text = styled.div`
  display: flex;
  gap: 8px;
`;

export { SlimContactCard };
export type { SlimContactCardProps };
