import styled from "@emotion/styled/macro";
import { Typography, tokens } from "@sunrun/experience-ui-components";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useKonami } from "react-konami-code";
import { type AxiosError } from "axios";
import llama1 from "../../assets/images/easterEggImages/llama1.png";
import llama2 from "../../assets/images/easterEggImages/llama2.png";
import llama3 from "../../assets/images/easterEggImages/llama3.png";
import llama4 from "../../assets/images/easterEggImages/llama4.png";
import llama5 from "../../assets/images/easterEggImages/llama5.png";
import llama6 from "../../assets/images/easterEggImages/llama6.png";
import llama7 from "../../assets/images/easterEggImages/llama7.png";
import llama8 from "../../assets/images/easterEggImages/llama8.png";
import llama9 from "../../assets/images/easterEggImages/llama9.png";
import llama10 from "../../assets/images/easterEggImages/llama10.png";
import llama11 from "../../assets/images/easterEggImages/llama11.png";
import llama12 from "../../assets/images/easterEggImages/llama12.png";
import {
  EditOffer,
  EditOfferProductAvailability,
  OfferLineCreate,
} from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { useAppSelector } from "store";
import {
  createOfferLines,
  deleteOfferLines,
  updateOfferLines,
} from "services/storeFront";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import { BundleRadioButton } from "components/molecules/BundleRadioButton";
import { IncludedProduct } from "components/organisms/IncludedProducts";
import { ProductAccessory } from "components/organisms/ProductAccessory";
import { AdHocAccessoryProduct, AdHocProduct } from "providers/Types";

type ErrorType = {
  error: Error | AxiosError | undefined;
  attemptedAction: string;
  hasRetry?: boolean;
};

type ProductSelectionOrganismProps = {
  offerId: string;
  productAvailability: EditOfferProductAvailability;
  onBundleLearnMore: () => void;
  bundleIdCache: React.MutableRefObject<string | undefined>;
  expectedLockVersion: React.MutableRefObject<number>;
  offerLinesIdLookup: React.MutableRefObject<EditOffer["lines"]>;
  onIsLoading?: (bool: boolean) => void;
  openProductErrorModal: () => void;
  setOfferLineErrors: React.Dispatch<
    React.SetStateAction<ErrorType | undefined>
  >;
  isOfferRetry: React.MutableRefObject<boolean>;
  currentBundle: React.MutableRefObject<number>;
  previousBundle: React.MutableRefObject<number>;
};
const ProductSelectionOrganism = ({
  offerId,
  productAvailability,
  onBundleLearnMore,
  bundleIdCache,
  expectedLockVersion,
  offerLinesIdLookup,
  onIsLoading,
  openProductErrorModal,
  setOfferLineErrors,
  isOfferRetry,
  currentBundle,
  previousBundle,
}: ProductSelectionOrganismProps) => {
  const getsBundleFromProducts = useCallback(
    () =>
      productAvailability.bundles.map((bundle, index) => {
        if (bundleIdCache && bundleIdCache.current === bundle.id) {
          currentBundle.current = index;

          if (!isOfferRetry.current) {
            previousBundle.current = index;
          }
        }
        return bundle;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productAvailability.bundles]
  );

  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const [bundles, setBundles] = useState<
    EditOfferProductAvailability["bundles"]
  >(getsBundleFromProducts);

  useEffect(() => {
    const bundle = getsBundleFromProducts();
    setBundles(bundle);
  }, [productAvailability.bundles, getsBundleFromProducts]);

  const [products, setProducts] = useState<Array<AdHocProduct>>(
    (): Array<AdHocProduct> =>
      productAvailability.products.map((product) => ({
        ...product,
        selectedVariantId: offerLinesIdLookup.current.find(
          (line) => line.variant.product.id === product.id
        )?.variant.id,
      }))
  );
  const [productAccessories, setProductAccessories] = useState<
    Array<AdHocAccessoryProduct>
  >(() =>
    productAvailability.products
      .filter(({ isStandaloneProduct }) => isStandaloneProduct)
      .map((product) => ({
        ...product,
        added: !!offerLinesIdLookup.current.find(
          (line) => line.variant.product.id === product.id
        ),
        quantity:
          offerLinesIdLookup.current.find(
            (line) => line.variant.product.id === product.id
          )?.quantity ?? 0,
        selectedVariantId: offerLinesIdLookup.current.find(
          (line) => line.variant.product.id === product.id
        )?.variant.id,
      }))
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    onIsLoading?.(loading);
  }, [loading, onIsLoading]);
  const getOfferLinesFromProductIds = useCallback(
    (
      productIds: string[],
      options?: {
        quantities?: number[];
        update?: boolean;
      }
    ) => {
      const { quantities, update } = options ?? {};
      return productIds.map((productId: string, mainIndex) => {
        const selectedProduct = products.find(
          (product) => product.id === productId
        );
        const lineId = offerLinesIdLookup.current.find(
          (line) => line.variant.product.id === productId
        )?.id;
        if (selectedProduct === undefined) {
          throw new Error(
            `Product with id ${productId} not found in product list`
          );
        }
        const isOffTheShelf = selectedProduct?.type === "OFF_THE_SHELF";
        return {
          ...(update && { id: lineId }),
          variantId: selectedProduct.defaultVariantId,
          ...(isOffTheShelf && { quantity: quantities?.[mainIndex] ?? 1 }), // quantity must have a value of at least 1
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [products]
  );
  const handleBundleChange = useCallback(
    async (value: string) => {
      if (!cognitoToken) {
        return;
      }
      bundleIdCache.current = value;
      currentBundle.current = bundles.findIndex(
        (bundle) => bundle.id === value
      );
      setLoading(true);
      const prevProductIds =
        previousBundle.current === -1
          ? []
          : bundles[previousBundle.current].productIds;
      const newProductIds = bundles[currentBundle.current].productIds;
      // in the old bundle, not the new
      const deleteProductIds = prevProductIds.filter(
        (oldId: string) => !newProductIds.includes(oldId)
      );
      // in the new bundle, not the old
      const createProductIds = newProductIds.filter(
        (newId: string) => !prevProductIds.includes(newId)
      );
      if (deleteProductIds.length > 0) {
        const deleteLineIds = offerLinesIdLookup.current
          .filter(
            (line) =>
              deleteProductIds.includes(line.variant.product.id) &&
              line.id !== undefined
          )
          .map((line) => line.id) as string[];
        try {
          const res = await deleteOfferLines(
            cognitoToken,
            offerId,
            deleteLineIds,
            expectedLockVersion.current
          );
          expectedLockVersion.current = res.lockVersion;
          offerLinesIdLookup.current = res.lines;
          previousBundle.current = currentBundle.current;
          setProducts((prevProducts) =>
            prevProducts.map((product) => ({
              ...product,
              quantity:
                res?.lines.find(
                  (line) => line.variant.product.id === product.id
                )?.quantity ?? 1,
              selectedVariantId: res?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.variant.id,
            }))
          );
          setProductAccessories((prevProductAccessories) =>
            prevProductAccessories.map((product) => ({
              ...product,
              quantity:
                res?.lines.find(
                  (line) => line.variant.product.id === product.id
                )?.quantity ?? 0,
              selectedVariantId: res?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.variant.id,
            }))
          );
        } catch (error: any) {
          setOfferLineErrors({
            attemptedAction: "Delete Offer line(s) from bundle selection",
            error,
            hasRetry: true,
          });
          openProductErrorModal();
        }
      }
      if (createProductIds.length > 0) {
        const createLines: Array<OfferLineCreate> =
          getOfferLinesFromProductIds(createProductIds);
        try {
          const res = await createOfferLines(
            cognitoToken,
            offerId,
            createLines,
            expectedLockVersion.current
          );
          expectedLockVersion.current = res.lockVersion;
          offerLinesIdLookup.current = res.lines;
          previousBundle.current = currentBundle.current;
          setProducts((prevProducts) =>
            prevProducts.map((product) => ({
              ...product,
              quantity:
                res?.lines.find(
                  (line) => line.variant.product.id === product.id
                )?.quantity ?? 1,
              selectedVariantId: res?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.variant.id,
            }))
          );
          setProductAccessories((prevProductAccessories) =>
            prevProductAccessories.map((product) => ({
              ...product,
              quantity:
                res?.lines.find(
                  (line) => line.variant.product.id === product.id
                )?.quantity ?? 0,
              selectedVariantId: res?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.variant.id,
            }))
          );
        } catch (error: any) {
          setOfferLineErrors({
            attemptedAction: "Create Offer line(s) from bundle selection",
            error,
            hasRetry: true,
          });
          openProductErrorModal();
        }
      }

      setLoading(false);
    },
    [
      getOfferLinesFromProductIds,
      expectedLockVersion,
      offerLinesIdLookup,
      offerId,
      cognitoToken,
      currentBundle,
      previousBundle,
      bundles,
      bundleIdCache,
      openProductErrorModal,
      setOfferLineErrors,
    ]
  );
  const handleProductVariantChange = useCallback(
    async (productId: string, variantId: string) => {
      if (!cognitoToken) {
        return;
      }
      const lines = getOfferLinesFromProductIds([productId], {
        update: true,
      });
      lines[0].variantId = variantId;
      setLoading(true);
      try {
        const response = await updateOfferLines(
          cognitoToken,
          offerId,
          lines,
          expectedLockVersion.current
        );
        expectedLockVersion.current = response.lockVersion;
        offerLinesIdLookup.current = response.lines;
        setProducts((prevProducts) =>
          prevProducts.map((product) => ({
            ...product,
            quantity:
              response?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.quantity ?? 1,
            selectedVariantId: response?.lines.find(
              (line) => line.variant.product.id === product.id
            )?.variant.id,
          }))
        );
        setProductAccessories((prevProductAccessories) =>
          prevProductAccessories.map((product) => ({
            ...product,
            quantity:
              response?.lines.find(
                (line) => line.variant.product.id === product.id
              )?.quantity ?? 0,
            selectedVariantId: response?.lines.find(
              (line) => line.variant.product.id === product.id
            )?.variant.id,
          }))
        );
      } catch (error: any) {
        console.error(error);
        setOfferLineErrors({
          attemptedAction: "Update Offer line(s) from product variant change",
          error: new Error("Failed to modify offer lines.", { cause: error }),
        });
        openProductErrorModal();
      } finally {
        setLoading(false);
      }
    },
    [
      getOfferLinesFromProductIds,
      expectedLockVersion,
      offerLinesIdLookup,
      offerId,
      cognitoToken,
      openProductErrorModal,
      setOfferLineErrors,
    ]
  );
  const handleAccessoryChange = useCallback(
    async (
      accessoryId: string,
      quantity: number,
      type: "add" | "delete" | "update"
    ) => {
      if (!cognitoToken) {
        return;
      }
      const lineOfferFunction = {
        add: createOfferLines,
        delete: deleteOfferLines,
        update: updateOfferLines,
      };
      setLoading(true);
      let response: EditOffer | undefined;
      if (type === "delete") {
        const deleteLineIds = offerLinesIdLookup.current
          .filter(
            (line) =>
              accessoryId === line.variant.product.id && line?.id !== undefined
          )
          .map((line) => line.id) as string[];
        try {
          response = await lineOfferFunction[type](
            cognitoToken,
            offerId,
            deleteLineIds,
            expectedLockVersion.current
          );
        } catch (error: any) {
          console.error(error);
          setOfferLineErrors({
            attemptedAction: "Delete Offer line(s) from accessory change",
            error,
          });
          openProductErrorModal();
        }
      } else {
        const lines = getOfferLinesFromProductIds([accessoryId], {
          update: type === "update",
          quantities: [quantity],
        });
        try {
          response = await lineOfferFunction[type](
            cognitoToken,
            offerId,
            lines,
            expectedLockVersion.current
          );
        } catch (error: any) {
          console.error(error);
          setOfferLineErrors({
            attemptedAction: "Update Offer line(s) from accessory change",
            error,
          });
          openProductErrorModal();
        }
      }
      if (response !== undefined) {
        expectedLockVersion.current = response.lockVersion;
        offerLinesIdLookup.current = response.lines;
        setProductAccessories((prevAccessories) =>
          prevAccessories.map((accessory) => ({
            ...accessory,
            added: !!response?.lines.find(
              (line) => line.variant.product.id === accessory.id
            ),
            quantity:
              response?.lines.find(
                (line) => line.variant.product.id === accessory.id
              )?.quantity ?? 0,
          }))
        );
      }
      setLoading(false);
    },
    [
      getOfferLinesFromProductIds,
      expectedLockVersion,
      offerLinesIdLookup,
      offerId,
      cognitoToken,
      openProductErrorModal,
      setOfferLineErrors,
    ]
  );
  useEffect(() => {
    if (bundles.length && isOfferRetry.current && bundleIdCache.current) {
      handleBundleChange(bundleIdCache.current);
      isOfferRetry.current = false;
    }
  }, [bundles, bundleIdCache, handleBundleChange, isOfferRetry]);
  // EasterEgg ;)
  const llamaImages = useMemo(
    () => [
      llama1,
      llama2,
      llama3,
      llama4,
      llama5,
      llama6,
      llama7,
      llama8,
      llama9,
      llama10,
      llama11,
      llama12,
    ],
    []
  );
  const [isKonamiLlamas, setIsKonamiLlamas] = useState<boolean>(false);
  const easterEgg = () => {
    setIsKonamiLlamas((isKonamiLlamas) => !isKonamiLlamas);
    console.log("konami llamas activated!");
  };
  useKonami(easterEgg);
  useEffect(() => {
    if (isKonamiLlamas) {
      setBundles((bundles) => {
        return bundles.map((bundle, index) => {
          return Object.assign({}, bundle, { imageUrl: llamaImages[index] });
        });
      });
      setProducts((products) => {
        return products.map((product, index: number) => {
          return Object.assign({}, product, {
            imageUrl: llamaImages[index + bundles.length],
          });
        });
      });
      setProductAccessories((productAccessories) => {
        return productAccessories.map((accessory, index) => {
          return Object.assign({}, accessory, {
            imgUrl: llamaImages[index + bundles.length + products.length],
          });
        });
      });
    }
  }, [isKonamiLlamas, bundles.length, llamaImages, products.length]);
  // End EasterEgg

  return (
    <section data-testid="product-selection-organism">
      <ProductPageSection>
        <SectionTitle>
          <Typography variant="h4">Bundle Options</Typography>
          <ModalOpener type="button" onClick={onBundleLearnMore}>
            Learn More
          </ModalOpener>
        </SectionTitle>
        <SectionContent>
          <SectionContentItemsWrapper>
            {bundles.map((bundleOption) => (
              <RadioGroupLabel key={bundleOption.title}>
                <BundleRadioButton
                  disabled={loading}
                  label={bundleOption.title}
                  value={bundleOption.id}
                  imageUrl={bundleOption.imageUrl}
                  description={bundleOption.description}
                  bundleId={bundleIdCache.current}
                  handleBundleChange={handleBundleChange}
                  data-testid={"standard-radio"}
                />
              </RadioGroupLabel>
            ))}
          </SectionContentItemsWrapper>
        </SectionContent>
      </ProductPageSection>
      <SectionHr />
      <ProductPageSection>
        <SectionTitle>
          <Typography variant="h4">Included Products</Typography>
        </SectionTitle>
        <SectionContent>
          <SectionContentItemsWrapper>
            {products.map((product) => {
              if (
                bundles[currentBundle.current].productIds.includes(product.id)
              ) {
                return (
                  <IncludedProduct
                    disabled={loading}
                    imageUrl={product.imageUrl}
                    key={product.title}
                    onUpdate={handleProductVariantChange}
                    productId={product.id}
                    selectedVariantId={product.selectedVariantId}
                    title={product.title}
                    variants={product.variants}
                  />
                );
              }
              return null;
            })}
          </SectionContentItemsWrapper>
        </SectionContent>
      </ProductPageSection>
      {!!productAccessories.length && (
        <>
          <SectionHr />
          <ProductPageSection>
            <SectionTitle>
              <Typography variant="h4">Add Energy Accessories</Typography>
            </SectionTitle>
            <SectionContent>
              <SectionContentItemsWrapper>
                {productAccessories.map((accessory) => (
                  <ProductAccessory
                    onAccessoryChange={handleAccessoryChange}
                    key={accessory.title}
                    productId={accessory.id}
                    title={accessory.title}
                    imageUrl={accessory.imageUrl}
                    added={!!accessory.added}
                    quantity={accessory.quantity!}
                    disabled={loading}
                    variants={accessory.variants}
                    selectedVariantId={accessory.selectedVariantId}
                    onVariantChange={handleProductVariantChange}
                  />
                ))}
              </SectionContentItemsWrapper>
            </SectionContent>
          </ProductPageSection>
        </>
      )}
    </section>
  );
};

const ProductPageSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0;
`;
const SectionTitle = styled.div`
  width: 20%;
  @media (max-width: 670px) {
    width: 100%;
    padding-bottom: 20px;
  }
  > h4 {
    padding-right: 20px;
    margin: 0;
  }
`;
const SectionContent = styled.div`
  width: 80%;
  @media (max-width: 670px) {
    width: 100%;
  }
`;
const SectionHr = styled.hr`
  border-style: solid;
  border-top-width: 0;
  border-color: ${tokens.OFFWHITE_40};
`;
const SectionContentItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
const RadioGroupLabel = styled.label`
  width: 233px;
  @media (max-width: 536px) {
    width: 100%;
  }
`;
const ModalOpener = styled(HyperlinkButton)`
  display: block;
  margin-top: 24px;
`;

export type { ProductSelectionOrganismProps, ErrorType };
export { ProductSelectionOrganism };
