import React from "react";
import { formatNumber, formatPercent } from "../../../utils/formatHelpers";
import {
  buildLeftMiddleRightRow,
  buildLeftRightRow,
} from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { RowProps } from "../../capTypes";

const SystemRows = ({ viewType }: RowProps) => {
  const { originalProposal, activeProposal } = useCapContext();

  const data = [
    [
      "Year 1 production",
      `${formatNumber(originalProposal?.year1ProductionForPricing ?? 0)} kWh`,
      `${formatNumber(activeProposal?.year1ProductionForPricing ?? 0)} kWh`,
    ],
    [
      "System size",
      `${originalProposal?.systemSize} kW DC`,
      `${activeProposal?.systemSize} kW DC`,
    ],
    [
      "Est. energy offset",
      `${formatPercent((originalProposal?.usageOffset ?? 0) / 100)}`,
      `${formatPercent((activeProposal?.usageOffset ?? 0) / 100)}`,
    ],
  ];
  let rows = [];
  if (viewType === "Single") {
    rows = data.map((term) => {
      return buildLeftRightRow(term, true);
    });
  } else {
    rows = data.map((term) => {
      return buildLeftMiddleRightRow(term, true);
    });
  }
  return <>{rows.map((row) => row)}</>;
};

export { SystemRows };
