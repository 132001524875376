import { chain } from "lodash";
import { SvgNames } from "@sunrun/experience-ui-components";
import {
  SplatCheckoutStep,
  UserProfile,
} from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import clipboardIcon from "../assets/images/clipboard.svg";
import documentDown from "assets/images/document-down.svg";
import documentIcon from "assets/images/document.svg";
import checkListIcon from "assets/images/check-list.svg";
import shieldChecked from "assets/images/shield-checked.svg";
import { CheckoutTask } from "checkout/api/checkoutSteps";

/**
 * This function is responsible for determining the final "Tasks" that show for
 * the end-user in the UI in One Checkout app. The input is assumed to be the
 * "checkoutSteps" that come from `splat-checkout` service.
 *
 * @param step
 * @param allSteps
 */
const mapStepToTask = (
  step: SplatCheckoutStep,
  userProfile?: UserProfile
): CheckoutTask | undefined => {
  const task: CheckoutTask = {
    id: step.name,
    icon: "",
    label: "",
    taskViewTitle: "",
    isComplete: step.completed,
    isUnlocked: step.isUnlocked,
    isInProgress:
      step.odis.some((odi) => !odi.completed) &&
      step.odis.some((odi) => odi.completed),
    order: step.order,
    shouldHideHeaderOnLoading: false,
  };

  switch (step.name) {
    case "confirmNames": {
      task.label = "Confirm Names";
      task.urlSlug = "confirm-names";
      task.icon = SvgNames.UserSvg;
      break;
    }
    case "checkCredit": {
      task.label = "Run Credit Check";
      task.urlSlug = "credit-check";
      task.icon = SvgNames.Billing;
      break;
    }
    case "loanApplicationApproval": {
      task.label = "Loan Approval";
      task.urlSlug = "loan-approval";
      task.icon = SvgNames.Bank;
      break;
    }
    case "readAndSignDocs": {
      task.label = "Read and Sign";
      task.taskViewTitle = "Read and Sign Documents";
      task.urlSlug = "sign-docs";
      task.icon = documentIcon;
      break;
    }
    case "linkUtilityAccount": {
      task.label = "Confirm Utility and Upload Docs";
      task.urlSlug = "confirm-utility";
      task.icon = checkListIcon;

      const relevantOdis = step.odis.filter(
        (odi) =>
          // SPLAT1-6135: Remove ODIs that are not relevant to the task completion
          !["nameOnBill", "nemAgreement", "usage"].includes(odi.odiName)
      );

      task.isComplete = (
        userProfile?.isPartner
          ? // If the user is a partner, only required ODIs are relevant to the task completion
            relevantOdis.filter((odi) => odi.isRequired)
          : relevantOdis
      ).every((odi) => odi.completed);
      task.isInProgress =
        relevantOdis.some((odi) => !odi.completed) &&
        relevantOdis.some((odi) => odi.completed);
      break;
    }
    case "deposit": {
      task.label = "Pay Deposit";
      task.icon = SvgNames.Pay;
      task.urlSlug = "pay-deposit";
      break;
    }
    case "enrollInAutoPay": {
      task.label = "Enroll in Autopay";
      task.icon = SvgNames.Pay;
      task.urlSlug = "autopay";
      break;
    }
    case "initiateSummaryCall": {
      task.label = "Verification Call";
      task.icon = shieldChecked;
      task.urlSlug = "summary-call";
      break;
    }
    case "designPreferences": {
      task.label = "Customer Preferences";
      task.icon = documentDown;
      task.urlSlug = "design-preferences";
      break;
    }
    case "scheduleSiteSurvey": {
      task.label = "Home Review";
      task.taskViewTitle = "Select a Home Review Option";
      task.shouldHideHeaderOnLoading = true;
      task.urlSlug = "site-survey";
      task.icon = clipboardIcon;
      break;
    }
    case "finalizeEFP": {
      task.label = "Finalize With Partner";
      task.urlSlug = "finalize-partner";
      task.icon = "shield-filled";
      task.isInProgress = true;
      task.isComplete = true;
      break;
    }
    default:
      throw new Error(`Unexpected checkout step received: ${step.name}`);
  }

  return task;
};

const mapCheckoutStepsToTasks = (
  steps: SplatCheckoutStep[],
  userProfile?: UserProfile
) => {
  return chain(steps)
    .map((step) => {
      return mapStepToTask(step, userProfile);
    })
    .compact()
    .sortBy("order")
    .value();
};

export { mapCheckoutStepsToTasks };
