import { API } from "aws-amplify";
import { DocumentEmail } from "../capTypes";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  jwt: string,
  path: string,
  body?: any,
  headers?: Record<string, unknown>
): Promise<Response> => {
  const response = await API[method]("OfferExpApi", path, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    },
    body: body,
  });
  return response as Response;
};

const postPropspectProposalEmail = async (
  jwt: string,
  prospectId: string,
  optionalNotes: string
): Promise<any> => {
  let url = `/prospect/${prospectId}/sendProposalEmail`;
  let body = {
    notes: optionalNotes,
  };
  return performRequest("post", jwt, url, body);
};

const postDocumentEmail = async (
  jwt: string,
  document: DocumentEmail,
  prospectId: string
): Promise<any> => {
  let url = `/prospect/${prospectId}/docEmail`;
  return performRequest("post", jwt, url, document);
};

export { postPropspectProposalEmail, postDocumentEmail };
