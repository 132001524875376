const STEPS = {
  SYSTEM_DESIGN: "Design" as const,
  HOME_READINESS: "Home readiness" as const,
  READ_SIGN: "Read & sign" as const,
  NEXT_STEPS: "Next steps" as const,
  EQUIPMENT_LOCATION: "Equipment location" as const,
} as const;

const CapSteps = [
  { name: STEPS.SYSTEM_DESIGN, order: 1 },
  { name: STEPS.EQUIPMENT_LOCATION, order: 2 },
  { name: STEPS.HOME_READINESS, order: 3 },
  { name: STEPS.READ_SIGN, order: 4 },
  { name: STEPS.NEXT_STEPS, order: 5 },
];

const AWAITING_AUDIT = "Awaiting site audit";
const AWAITING_DESIGN = "Awaiting design";
const AWAITING_PC = "Awaiting PC";
const CLOSED = "Closed";
const INACTIVE = "Inactive";
const OPEN = "Open";

export {
  CapSteps,
  STEPS,
  AWAITING_PC,
  AWAITING_DESIGN,
  AWAITING_AUDIT,
  CLOSED,
  INACTIVE,
  OPEN,
};
