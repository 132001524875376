import useSWR from "swr";
import {
  ListOffer,
  ReviewOffer,
} from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { SplatLoan } from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { SplatProspectResponse } from "../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import type { CMSCheckoutStep } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { SplatCheckoutDocument } from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { getWithAuth } from "utils/dataUtils";
import { useAuthToken } from "hooks/useAuthToken";

const useOfferList = (prospectId: string, cognitoToken?: string) => {
  const {
    data: offerList,
    isLoading: isOfferListLoading,
    error: offerListError,
  } = useSWR(
    [`/offers/prospect/${prospectId}`, cognitoToken],
    getWithAuth<ListOffer[]>
  );
  console.log(offerList);

  return { offerList, offerListError, isOfferListLoading };
};

const useOffer = (offerId?: string, cognitoToken?: string) => {
  const shouldFetch = offerId && cognitoToken;
  const {
    data: offer,
    error: offerError,
    isLoading: isOfferLoading,
  } = useSWR(
    shouldFetch && [`/offers/${offerId}`, cognitoToken],
    getWithAuth<ReviewOffer>
  );

  return { offer, offerError, isOfferLoading };
};

const useLoans = (
  prospectId?: string,
  hybridToken?: string,
  orderByOldToNew = false
): {
  loans?: SplatLoan[];
  isLoansLoading: boolean;
  loansError: Error | null;
  refetchLoans: () => void;
  isValidatingLoans?: boolean;
} => {
  const shouldFetch = prospectId && hybridToken;
  const {
    data: loans,
    error: loansError,
    isLoading: isLoansLoading,
    mutate: refetchLoans,
    isValidating: isValidatingLoans,
  } = useSWR(
    shouldFetch && [`/prospect/${prospectId}/loan`, hybridToken],
    getWithAuth<SplatLoan[]>
  );

  return {
    loans: orderByOldToNew ? [...(loans || [])]?.reverse() : loans,
    loansError,
    isLoansLoading,
    refetchLoans,
    isValidatingLoans,
  };
};

const useLoanApplicationUrl = (
  prospectId?: string,
  proposalId?: string,
  hybridToken?: string,
  coBorrowerId?: string
): {
  loanApplicationUrl?: string;
  isLoanUrlLoading: boolean;
  loanUrlError: Error | null;
  isLoanUrlValidating?: boolean;
} => {
  const shouldFetch = prospectId && proposalId && hybridToken;
  const urlKey = `/prospect/${prospectId}/proposal/${proposalId}/loan/application/url${
    coBorrowerId ? `?coborrower=${coBorrowerId}` : ""
  }`;

  const {
    data,
    error: loanUrlError,
    isLoading: isLoanUrlLoading,
    isValidating: isLoanUrlValidating,
  } = useSWR(
    shouldFetch && [urlKey, hybridToken],
    getWithAuth<{
      url: string;
    }>
  );

  return {
    loanApplicationUrl: data?.url,
    loanUrlError,
    isLoanUrlLoading,
    isLoanUrlValidating,
  };
};

const useProspect = (
  prospectId?: string
): {
  data?: SplatProspectResponse;
  isLoading: boolean;
  error: Error | null;
} => {
  const hybridAuth = useAuthToken("HYBRID");
  const shouldFetch = prospectId && hybridAuth;
  const { data, error, isLoading } = useSWR(
    shouldFetch && [`/prospect/${prospectId}`, hybridAuth.token],
    getWithAuth<SplatProspectResponse>
  );
  return {
    data,
    isLoading,
    error,
  };
};

const useCMSCheckoutSteps = (stepId?: string) => {
  const hybridAuth = useAuthToken("HYBRID");
  const shouldFetch = stepId && hybridAuth;
  const { data, error, isLoading } = useSWR(
    shouldFetch && [`/cmsCheckoutSteps/${stepId}`, hybridAuth.token],
    getWithAuth<CMSCheckoutStep>
  );

  return { data, error, isLoading };
};

const useCheckoutAttachments = (
  prospectId: string,
  proposalId: string,
  documentType: string
) => {
  const hybridAuth = useAuthToken("HYBRID");
  const shouldFetch = prospectId && proposalId && hybridAuth;
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch && [
      `/prospect/${prospectId}/proposals/${proposalId}/attachments?documentType=${documentType}`,
      hybridAuth.token,
    ],
    getWithAuth<SplatCheckoutDocument[]>
  );

  return { data, error, isLoading, mutate };
};
const useCheckoutAttachmentsNoType = (
  prospectId: string,
  proposalId: string
) => {
  const hybridAuth = useAuthToken("HYBRID");
  const shouldFetch = prospectId && proposalId && hybridAuth;
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch && [
      `/prospect/${prospectId}/proposals/${proposalId}/attachments`,
      hybridAuth.token,
    ],
    getWithAuth<SplatCheckoutDocument[]>
  );

  return { data, error, isLoading, mutate };
};

export {
  useOfferList,
  useOffer,
  useLoans,
  useLoanApplicationUrl,
  useProspect,
  useCMSCheckoutSteps,
  useCheckoutAttachments,
  useCheckoutAttachmentsNoType,
};
